<template>
  <v-main>
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container class="py-8 pt-4" v-if="!isLoading && fields">
      <v-breadcrumbs :items="items" divider="/" class="px-2"></v-breadcrumbs>
      <v-row>
        <v-col cols="12" class="pa-5">
          <h3 class="text-h5 text-md-h4 primary--text d-inline-block mb-5">
            {{ $t("contact") }}
          </h3>
          <prismic-rich-text :field="fields.body" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template><script>
export default {
  title: window.vm.$i18n.t("contact"),
  data: () => ({
    isLoading: true,
    categories: [],
    document: null,
  }),
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-gb";
    },
    fields() {
      return this.document
        ? this.document.data
        : {
            title: [],
            body: [],
          };
    },
    category() {
      if (!this.fields) {
        return {};
      }
      return this.categories.find((c) => c.slug == this.document.tags[0]);
    },
    items() {
      return [
        {
          text: this.$i18n.t("support"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$i18n.t("contact"),
          disabled: true,
        },
      ];
    },
  },
  methods: {
    async loadContent() {
      this.isLoading = true;
      await this.getContent("contact");
      this.isLoading = false;
    },
    async getContent(uid) {
      const document = await this.$prismic.client.query(
        this.$prismic.Predicates.at("my.article.uid", uid),
        { lang: this.locale }
      );
      this.document = document.results[0];
    },
  },
  created() {
    this.loadContent();
  },
};
</script>
<style>
img {
  max-width: 100%;
}
</style>